<template>
  <div class="container">
    <b-modal id="modal-account" hide-footer size="lg">
      <template v-slot:modal-title>
        <div class="row">
          <div class="col-md-2">
            <font-awesome-icon icon="user-circle" />
          </div>
          <div class="col-md-9">
            {{ $t('account') }}
          </div>
        </div>
      </template>
      <b-card class="mb-4">
        <template #header>
          <h6 class="mb-0 float-left">
            {{ userinfo.userDisplayName }}
          </h6>
        </template>
        <b-card-text>
          <b-row>
            <div class="column col-lg-10 col-8">
              {{ keycloakUserProfile.email }}
              <span v-if="userAttributes.organisation">
                {{ $t('userOrganisation') }}
                <span
                  v-for="value in userAttributes.organisation"
                  :key="value"
                >
                  {{ value }}
                </span>
              </span>
              <span v-if="userAttributes.cardId">
                {{ $t('cardId') }}
                <span
                  v-for="value in userAttributes.cardId"
                  :key="value"
                >
                  {{ value }}
                </span>
              </span>
              <b-badge
                v-if="userAttributes.isEmergencyResponder"
                variant="success"
              >
                {{ $t('isEmergencyResponder') }}
              </b-badge>
            </div>
            <div class="column col-lg-2 col-4">
              <b-button
                variant="outline-secondary"
                size="sm"
                class="float-right"
                @click="logout"
              >
                <font-awesome-icon icon="sign-out-alt" />
                {{ $t('logout') }}
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>

      <b-card class="mb-4 pb-0" no-body>
        <template #header>
          <h6 v-b-toggle.authorisations class="mb-0">
            {{ $t('authorisations') }}
          </h6>
          <font-awesome-icon
            :icon="openAuthorisations ? 'caret-up' : 'caret-down'"
            class="collapse-down-icon"
            @click="openAuthorisations = !openAuthorisations"
          />
        </template>
        <b-collapse id="authorisations" v-model="openAuthorisations">
          <b-card-body>
            <b-card-text>
              <span v-if="userAttributes.overrideUserpool">
                {{ $t('yourAccountType') }}
                <span
                  v-for="value in userAttributes.overrideUserpool"
                  :key="value"
                >
                  <b-badge variant="success" class="mb-1">{{
                    value
                  }}</b-badge>
                </span>
              </span>
              <span v-else-if="userAttributes.userpool">
                {{ $t('yourAccountType') }}
                <span
                  v-for="value in userAttributes.userpool"
                  :key="value"
                >
                  <b-badge variant="success">{{ value }}</b-badge>
                </span>
              </span>
              <span
                v-if="
                  Array.isArray(userinfo.roles) &&
                    userinfo.roles.length > 0
                "
              >
                {{ $t('yourRoles') }}
                <span v-for="value in userinfo.roles" :key="value">
                  <b-badge variant="primary" class="mr-1 mb-1">
                    {{ value }}
                  </b-badge>
                </span>
              </span>
              <div v-if="userinfo.roles">
                {{ $t('youHaveAccessToBuildings') }}
                <span
                  v-for="group in userinfo.groups"
                  :key="group.orgName"
                >
                  <b-badge variant="info" class="mr-1">
                    {{ group.orgName }}
                  </b-badge>
                </span>
                <span
                  v-if="Object.keys(userinfo.groups).length === 0"
                >
                  -
                </span>
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card
        :v-if="
          userAttributes.futureReservationMaximum ||
            userAttributes.weeklyReservationHourLimit ||
            userAttributes.weeklyReservationMinuteLimit
        "
        class="mb-4"
        no-body
      >
        <template #header>
          <h6 v-b-toggle.personalReservationSettings class="mb-0">
            {{ $t('personalReservationSettings') }}
          </h6>
          <font-awesome-icon
            :icon="
              openPersonalReserervationSettings
                ? 'caret-up'
                : 'caret-down'
            "
            class="collapse-down-icon"
            @click="
              openPersonalReserervationSettings = !openPersonalReserervationSettings
            "
          />
        </template>
        <b-collapse
          id="personalReservationSettings"
          v-model="openPersonalReserervationSettings"
        >
          <b-card-body>
            <b-card-text>
              <div v-if="userAttributes.futureReservationMaximum">
                {{ $t('youMayReserve') }}
                <span
                  v-for="value in userAttributes.futureReservationMaximum"
                  :key="value"
                >
                  {{ value }}
                </span>
                {{ $t('daysAhead') }}
              </div>
              <div v-if="userAttributes.weeklyReservationHourLimit">
                {{ $t('youMayReserve') }}
                <span
                  v-for="value in userAttributes.weeklyReservationHourLimit"
                  :key="value"
                >
                  {{ value }}
                </span>
                {{ $t('hoursPerWeek') }}
              </div>
              <div v-if="userAttributes.weeklyReservationMinuteLimit">
                {{ $t('youMayReserve') }}
                <span
                  v-for="value in userAttributes.weeklyReservationMinuteLimit"
                  :key="value"
                >
                  {{ value }}
                </span>
                {{ $t('minutesPerWeek') }}
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-link
        class="float-right mr-4"
        target="_blank"
        href="https://www.okku.io/privacy"
      >
        {{ $t('privacyPolicy') }}
      </b-link>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import OkkuApi from '@/services/OkkuApi'
import { mapState } from 'vuex'
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

export default {
  props: {
    userinfo: { required: true, type: Object },
    keycloakUserProfile: { required: true, type: Object }
  },
  data() {
    return {
      openAuthorisations: false,
      openPersonalReserervationSettings: false
    }
  },
  computed: {
    ...mapState('common', {
      userInfo: '$userInfo'
    }),
    rewardsUrl() {
      return {
        name: 'Rewards'
      }
    },
    userAttributes() {
      return this.keycloakUserProfile.attributes || {}
    },

    ...mapState('common', ['isKiosk'])
  },
  async mounted() {
    console.log('AccountModal.mounted')
  },
  methods: {
    async logout() {
      await OkkuApi.logout()
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
#modal-account {
  .collapse-down-icon {
    position: absolute;
    right: 12px;
    top: 14px;
    font-size: 1rem;
    cursor: pointer;
  }
}
</style>
