var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{attrs:{"id":"main-nav"}},[(_vm.hasOrganisationAccess)?_c('b-navbar',{attrs:{"toggleable":"xl","type":"light"}},[_c('div',{staticClass:"mobile-top col-xl-auto"},[_c('b-navbar-brand',[_c('b-nav-item',{attrs:{"to":{ name: 'BuildingsPage' }}},[_c('img',{staticClass:"logo-top",attrs:{"src":_vm.organisationUrls.logoUrl,"alt":""}})])],1),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}})],1),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"col"},[(_vm.activeGroup)?[(_vm.reservationsEnabled)?_c('b-nav-item',{staticClass:"d-none d-xl-block",attrs:{"to":{ name: 'WorkplaceReservationPage' }}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.activeGroup.orgName))])]):_vm._e(),(!_vm.reservationsEnabled && _vm.sensorsEnabled)?_c('b-nav-item',{staticClass:"d-none d-xl-block",attrs:{"to":{ name: 'SensorsDataPage' }}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.activeGroup.orgName))])]):_vm._e()]:_vm._e(),(
            _vm.pageName != 'BuildingsPage' && _vm.userHasMultipleLocations
          )?_c('div',{staticClass:"change-button"},[_c('div',[_c('router-link',{staticClass:"btn btn-secondary",attrs:{"title":_vm.$t('changeBuilding'),"to":{ name: 'BuildingsPage' }}},[_vm._v(" "+_vm._s(_vm.$t('locations'))+" ")])],1)]):_vm._e(),(_vm.userInfo.roles && _vm.pageName != 'BuildingsPage')?[(_vm.isAdminOrCoordinator)?_c('b-nav-item',{attrs:{"to":{ name: 'AllReservationsPage' }}},[_vm._v(" "+_vm._s(_vm.$t('manage'))+" ")]):_vm._e(),(_vm.sensorsEnabled)?_c('b-nav-item-dropdown',{attrs:{"toggle-class":"text-decoration-none","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('sensors'))+" ")]},proxy:true}],null,false,1763809046)},[_c('b-dropdown-item',{attrs:{"to":{ name: 'SensorsDataPage' }}},[_vm._v(" "+_vm._s(_vm.$t('sensorsOnMap'))+" ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'ClimateIndicators' }}},[_vm._v(" "+_vm._s(_vm.$t('climateIndicators'))+" ")])],1):_vm._e(),(_vm.insightsEnabled)?_c('b-nav-item-dropdown',{attrs:{"right":"","toggle-class":"text-decoration-none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('insights'))+" ")]},proxy:true}],null,false,3220823750)},[_c('b-dropdown-item',{attrs:{"to":{ name: 'UserSummaries' }}},[_vm._v(" "+_vm._s(_vm.$t('userSummaries'))+" ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'HourlyAvailability' }}},[_vm._v(" "+_vm._s(_vm.$t('hourlyAvailability'))+" ")]),_c('b-dropdown-item',{attrs:{"to":{
                name: 'ReservationsAndCancellationsStatistics'
              }}},[_vm._v(" "+_vm._s(_vm.$t('reservationsAndCancellationsStatistics'))+" ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'ReservationDetails' }}},[_vm._v(" "+_vm._s(_vm.$t('reservations'))+" ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'FixedDesks' }}},[_vm._v(" "+_vm._s(_vm.$t('fixedDesks'))+" ")]),(_vm.isDashboardAdmin || _vm.hasViewCustomAssetRole)?_c('b-dropdown-item',{attrs:{"to":{ name: 'AssetsAvailability' }}},[_vm._v(" "+_vm._s(_vm.$t('assetsAvailability'))+" ")]):_vm._e(),_c('b-dropdown-item',{attrs:{"href":_vm.userInfo.insightsLink}},[_vm._v(" "+_vm._s(_vm.$t('customReports'))+" "),_c('font-awesome-icon',{staticClass:"mb-1 ml-3",attrs:{"icon":"external-link-alt"}})],1)],1):_vm._e()]:_vm._e(),(_vm.reservationsEnabled)?_c('b-nav-item',{attrs:{"to":{ name: 'MyReservationsPage' }}},[_vm._v(" "+_vm._s(_vm.$t('myReservations'))+" ")]):_vm._e(),(
            _vm.findMyColleaguesEnabled && _vm.pageName !== 'BuildingsPage'
          )?_c('b-nav-item',{attrs:{"to":{ name: 'FindMyColleaguesPage' }}},[_vm._v(" "+_vm._s(_vm.$t('findMyColleague'))+" ")]):_vm._e(),(_vm.organisationUrls.infoUrl && !_vm.isKiosk)?_c('b-nav-item',{attrs:{"target":"_blank","href":_vm.organisationUrls.infoUrl}},[_vm._v(" "+_vm._s(_vm.$t('faq'))+" ")]):_vm._e(),(!_vm.organisationUrls.infoUrl && !_vm.isKiosk)?_c('b-nav-item',{attrs:{"target":"_blank","href":"https://okku.io/support"}},[_vm._v(" "+_vm._s(_vm.$t('faq'))+" ")]):_vm._e()],2),_c('b-navbar-nav',{staticClass:"align-self-end  col-md-auto"},[_c('b-nav-item-dropdown',{staticClass:"dropdown-user",attrs:{"toggle-class":"text-decoration-none","left":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"globe"}}),_vm._v(" "+_vm._s(_vm.currentLang)+" ")]},proxy:true}],null,false,3829862757)},_vm._l((_vm.languages),function(entry){return _c('b-dropdown-item',{key:entry.title,attrs:{"href":"#"},on:{"click":function($event){return _vm.changeLocale(entry.language, entry.title)}}},[_c('flag',{attrs:{"iso":entry.flag,"squared":false}}),_vm._v(" "+_vm._s(entry.title)+" ")],1)}),1),_c('b-nav-item-dropdown',{staticClass:"dropdown-user",attrs:{"right":"","toggle-class":"text-decoration-none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"user-circle"}}),_vm._v(" "+_vm._s(_vm.userInfo.userDisplayName)+" ")]},proxy:true}],null,false,2153684728)},[(_vm.isDashboardAdmin)?_c('b-dropdown-item',{attrs:{"to":{ name: 'OrganisationsAdminPage' }}},[_vm._v(" "+_vm._s(_vm.$t('organisations'))+" ")]):_vm._e(),(_vm.isDashboardAdmin)?_c('b-dropdown-item',{attrs:{"to":{ name: 'BuildingsAdminPage' }}},[_vm._v(" "+_vm._s(_vm.$t('buildings'))+" ")]):_vm._e(),(_vm.isDashboardAdmin)?_c('b-dropdown-divider'):_vm._e(),(
              _vm.reservationsEnabled &&
                _vm.userInfo.showPlannedAbsencesScreen
            )?_c('b-dropdown-item',{attrs:{"to":{ name: 'MyAbsencesPage' }}},[_vm._v(" "+_vm._s(_vm.$t('myAbsences'))+" ")]):_vm._e(),_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-account",modifiers:{"modal-account":true}}]},[_c('font-awesome-icon',{attrs:{"icon":"user-circle"}}),_vm._v(" "+_vm._s(_vm.$t('account'))+" ")],1),_c('b-dropdown-item',{attrs:{"disabled":""}},[_c('font-awesome-icon',{attrs:{"icon":"user-friends"}}),_vm._v(" "+_vm._s(_vm.$t('myTeams'))+" ")],1)],1)],1)],1)],1):_c('b-navbar',{attrs:{"toggleable":"lg","type":"light"}},[_c('b-navbar-nav',{staticClass:"ml-right"},[_c('b-dropdown',{staticClass:"dropdown-user",attrs:{"right":"","variant":"link","toggle-class":"text-decoration-none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"user-circle"}}),_vm._v(" "+_vm._s(_vm.userInfo.userDisplayName)+" ")]},proxy:true}])},[_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-account",modifiers:{"modal-account":true}}]},[_c('font-awesome-icon',{attrs:{"icon":"user-circle"}}),_vm._v(" "+_vm._s(_vm.$t('account'))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }